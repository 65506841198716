<template>
  <div>
    <div style="margin: 10px 0">
      <el-input style="width: 200px" placeholder="请输入名称" suffix-icon="el-icon-search" v-model="name"></el-input>
      <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
    </div>
    <div style="margin: 10px 0">
      <el-button type="primary" class="ml-5" @click="uploadClick">上传文件 <i class="el-icon-top"></i></el-button>

    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部" name="all"></el-tab-pane>
      <el-tab-pane v-for="(item, index) in options" :key="index" :label="item.label" :name="item.value">

      </el-tab-pane>
    </el-tabs>
    <el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"
      @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" label="ID" width="80"></el-table-column>
      <el-table-column width="200" label="封面图片">
        <template slot-scope="scope">
          <img :src="scope.row.imageThumbUrl" alt="封面图片" style="width:100px;height: 100px;object-fit: cover;" />
        </template>
      </el-table-column>
      <el-table-column prop="name" label="文件名称"></el-table-column>
      <el-table-column prop="typeName" label="文件类型"></el-table-column>
      <el-table-column prop="description" label="文件描述"></el-table-column>
      <el-table-column label="下载">
        <template slot-scope="scope">
          <el-button type="primary" @click="download(scope.row.id)">下载</el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" align="center">
        <template slot-scope="scope">
          <el-popconfirm class="ml-5" confirm-button-text='确定' cancel-button-text='我再想想' icon="el-icon-info"
            icon-color="red" title="您确定删除吗？" @confirm="del(scope.row.id)">
            <el-button type="danger" slot="reference">删除 <i class="el-icon-remove-outline"></i></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <div style="padding: 10px 0">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
        :page-sizes="[2, 5, 10, 20]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>

    <el-dialog title="文件上传" :visible.sync="dialogFormVisible" width="30%" :close-on-click-modal="false">
      <el-form ref="form" :model="form" label-width="100px" size="small" style="width: 90%" :rules="rules">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input v-model="form.description" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="文件类型" prop="typeId">
          <el-select v-model="form.typeId" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="封面图片" prop="imgFile">
          <el-upload ref="imgUpload" action="#" :auto-upload="false" :limit="1" :show-file-list="true"
            :on-change="onImgChange" :on-remove="onImgRemove" accept=".jpeg,.jpg,.png">
            <el-button type="primary" class="ml-5">上传 <i class="el-icon-top"></i></el-button>
          </el-upload>
        </el-form-item>

        <el-form-item label="文件" prop="file">
          <el-upload ref="upload" action="#" :auto-upload="false" :limit="1" :show-file-list="true"
            :on-change="onChange" :on-remove="onRemove">
            <el-button type="primary" class="ml-5">上传 <i class="el-icon-top"></i></el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "File",
  data() {
    return {
      activeName: 'all',
      tableData: [],
      name: '',
      multipleSelection: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      dialogFormVisible: false,
      form: {
        name: '',

      },
      options: [],
      fileList: [],
      imgFileList: [],
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        description: [
          { required: true, message: '请输入描述', trigger: 'blur' },
        ],
        typeId: [
          { required: true, message: '请选择文件类型', trigger: 'change' },
        ],
        imgFile: [
          { required: true, message: '请选择封面图片', trigger: 'change' },
        ],
        file: [
          { required: true, message: '请选择文件', trigger: 'change' },
        ],
      }
    }
  },
  async created() {
    await this.fetchMediaTypeAll()
    this.load()
  },
  methods: {
    handleClick() {//切换tab页码重置为1
      this.pageNum = 1
      this.load()
    },
    async fetchMediaTypeAll() {
      const res = await this.request.get("/media-type/all")
      if (res && res.code == 0) {
        this.options = res.data.map((d) => {
          return {
            label: d.typeName,
            value: d.id
          }
        })
      }
    },
    uploadClick() {
      this.dialogFormVisible = true
    },
    onImgRemove(file, fileList) {
      console.log("onImgRemove file:", file, " fileList:", fileList)
      this.imgFileList = fileList
    },
    onImgChange(file, fileList) {
      console.log("onImgChange file:", file, " fileList:", fileList)
      this.imgFileList = fileList
      this.form.imgFile = this.imgFileList && this.imgFileList.length ? '1' : ''
      this.$refs.form.validateField('imgFile')
    },
    onChange(file, fileList) {
      console.log("onChange file:", file, " fileList:", fileList)
      this.fileList = fileList
      this.form.file = this.fileList && this.fileList.length ? '1' : ''
      this.$refs.form.validateField('file')
    },
    onRemove(file, fileList) {
      console.log("onRemove file:", file, " fileList:", fileList)
      this.fileList = fileList
    },
    save() {
      console.log("save imgFileList:", this.imgFileList)
      this.$refs.form.validate(async (valid) => {
        console.log("valid:", valid)
        if (valid) {//上传文件
          // console.log("valid")

          var formData = new FormData();
          formData.append('name', this.form.name);
          formData.append('typeId', this.form.typeId)
          formData.append('description', this.form.description)
          formData.append('imageFile', this.imgFileList[0].raw)
          formData.append('file', this.fileList[0].raw)
          let { code, data } = await this.request({
            url: '/media/upload',
            method: 'POST',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: formData
          });
          if (code == 0) {
            this.$refs.form.resetFields()
            this.$refs.imgUpload.clearFiles()
            this.imgFileList = []

            this.$refs.upload.clearFiles()
            this.fileList = []

            this.dialogFormVisible = false
            this.$message.success("上传成功")
            this.load()
          }

        }
      })
    },
    load() {
      const typeId = this.activeName === 'all' ? '' : this.activeName
      let params = {
        page: this.pageNum,
        limit: this.pageSize
      }
      if (typeId) {
        params = {
          ...params,
          condition: {
            typeId: typeId
          }
        }
      }
      this.request.post("/media/page", {
        ...params
      }).then(res => {

        this.tableData = res.data.records
        this.total = res.data.total

      })
    },
    changeEnable(row) {
      this.request.post("/file/update", row).then(res => {
        if (res.code === '200') {
          this.$message.success("操作成功")
        }
      })
    },
    del(id) {
      this.request.post("/media/remove?id=" + id).then(res => {
        if (res.code == 0) {
          this.$message.success("删除成功")
          this.load()
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    reset() {
      this.name = ""
      this.load()
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    handleFileUploadSuccess(res) {
      this.$message.success("上传成功")
      this.load()
    },
    download(id) {
      this.request.get("/media/get-download-url?id=" + id).then((res) => {
        if (res.code == 0) {
          const url = res.data
          window.open(url)
        }
      })

    },
  }
}
</script>

<style scoped></style>
